import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";

export default function ExpandComponent({
  open,
  title,
  subTitle,
  close,
  dark,
  light,
  children,
}) {
  const titleRef = useRef(null);
  const [titleDivHeight] = useState(0);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-60"
        open={open}
        onClose={() => close(false)}
      >
        <div className="flex items-end justify-center min-h-screen text-center sm:block">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              onClick={() => close(false)}
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            // afterEnter={() => {
            //   setTitleDivHeight(titleRef?.current?.clientHeight);
            // }}
            // afterLeave={() => {}}
          >
            <div
              style={{
                background: `${
                  dark ? "#000000ad" : light ? "#ffffff" : "#ffffff69"
                }`,
              }}
              className="h-screen w-full inline-block text-left transform transition-all align-middle"
            >
              <div className="mx-auto relative" style={{ maxWidth: "1920px" }}>
                <div className="absolute top-0 right-0 pt-4 sm:pt-5 pr-4 sm:pr-6">
                  <button
                    type="button"
                    className="text-gray-500 hover:text-gray-800 focus:outline-none focus:ring-0"
                    onClick={() => close(false)}
                  >
                    <span className="sr-only">Close</span>
                  </button>
                </div>

                <div ref={titleRef}>
                  <Dialog.Title
                    as="h3"
                    className="text-xl sm:text-2xl leading-6 font-bold text-black-1"
                  >
                    <div className="px-8">
                      <div
                        className={`flex ${
                          title ? "justify-between" : "justify-end"
                        } items-center gap-5`}
                      >
                        {title && (
                          <span>
                            <h1 className="font-semibold text-lg">{title}</h1>
                            <p className=" text-xs">{subTitle ?? ""}</p>
                          </span>
                        )}
                        <div>
                          <i
                            onClick={() => close(false)}
                            class={`fa-solid cursor-pointer text-sm ${
                              light ? "text-black" : "text-white"
                            } hover:text-blue-400 fa-down-left-and-up-right-to-center`}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </Dialog.Title>
                </div>

                <div
                  className="pb-10"
                  style={{
                    height: `calc(100vh - ${titleDivHeight}px)`,
                    overflowY: "auto",
                  }}
                >
                  {/* content */}
                  {children}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
