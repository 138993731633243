import { useEffect, useState } from "react";
import logo from "./images/logo.png";
import spring from "./images/spring.png";
import union from "./images/Union.png";
import gal1 from "./images/gal1.png";
import gal2 from "./images/gal2.png";
import gal3 from "./images/gal3.png";
import gal4 from "./images/gal4.png";
import gal5 from "./images/gal5.png";
import gal7 from "./images/gal7.png";
import cont from "./images/cont.png";
import flogo from "./images/flogo.png";
import staffing from './images/staffing.jpg'
import project from './images/project.jpg'
import panel from './images/panel.jpg'
import automation from './images/automation.jpg'
import facility from './images/facility.jpg'
import management from './images/management.jpg'
import electrical from './images/electrical.jpg'
import { FaFacebook, FaLinkedinIn, FaPhoneAlt, FaSpinner, FaTimesCircle } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { GrUserWorker } from "react-icons/gr";
import { SiConstruct3 } from "react-icons/si";
import { SiBandrautomation } from "react-icons/si";
import { BsTools } from "react-icons/bs";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { MdManageAccounts } from "react-icons/md";
import { GiCircuitry } from "react-icons/gi";
import { Link } from "react-scroll";
import { BsWhatsapp } from "react-icons/bs";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addDoc, collection } from "firebase/firestore";
import { database } from "./firebaseConfig";
import phone from "phone";
import useUserCountry from "./hooks/useCountry";
import ExpandComponent from "./component/Modal";

function App() {
  const [pannel, setPannel] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userCountry } = useUserCountry();
  const postRef = collection(database, "posts");
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    contact: "",
    message: "",
    country: "",
  });
  const [selectedService, setSelectedService] = useState(null)
  const services = [
    {
      title: 'Facilities Management / Maintenance',
      count: 1,
      icon: <GiCircuitry className="text-xs md:text-2xl" />,
      image: management,
      quotes: 'A lot of emphasis are placed on new property development and a very few attention to the maintenance repairs of existing buildings,plants and equipment. This company was established by experienced hands, with  experience in high-rise building maintenance, equipment maintenance, providing quick facilities Solutions. we maintain school facilities, churches, hotels,  grocery stores, offices, company facilities, mall, sitting park, Homes apartments with highest safety in mind'
    },
    {
      title: 'Project Support',
      count: 2,
      icon: <AiOutlineFundProjectionScreen className="text-xs md:text-2xl" />,
      image: project,
      quotes: `
          We provide projects and contracts support services to agencies in the private and public sector, general contracts and subcontracts. we are experienced in project support services. We provide experienced project staffing assistance, project rather than random looking for staffing. We are established to support project team in achieving a timely goals in a safe manner. We offer integrated Solutions to micro small and large project assistant services , planning and tracking progress as well as providing pinpoint accuracy in identifying problems and suggesting Solutions.  project equipment rental, QA QC services , project management for organisation and the planning and execution of projects, risk and project management.
      `
    },
    {
      title: 'Automation and Control',
      count: 5,
      icon: <SiBandrautomation className="text-xs md:text-2xl" />,
      image: automation,
      quotes: `
      Synchronization panel,design and construction of automatic control panels,automation and control, electronic and instrumentation engineering services, contractual staffing, electrical project management, upgrade of  electrical design works ,electrical circuit maintenance, 
      preventive, predictive and corrective maintenance of electrical and electronics circuits, automatic control Street light installation, automatic control solar system panels (AC and DC)
      we use latest web-based technology and highly skilled workers and design personnel, auto synchronisation and load sharing panel design, distribution and starter control panel
      `
    },
    {
      title: 'Electrical and Mechanical Engineering Services',
      count: 4,
      icon: <BsTools className="text-xs md:text-2xl" />,
      image: electrical,
      quotes: `
      Mechatric limited  is the trusted name in the construction and installation of any kind of electrical and mechanical engineering equipment, fittings, solar light system control panels. We work to city required standard and install to specification and safety standard
      `
    },
    {
      title: 'Contractual Staffing',
      count: 4,
      icon: <GrUserWorker className="text-xs md:text-2xl" />,
      image: staffing,
      quotes: `
      Mechatric limited has a good number of highly qualified and skilled employees with half of the number contracted to clients to facilitate operations. We maintain a database of previous and potential employees who have a variety of specialist skills. We outsource project management assistants, engineers and technicians
      `
    },
    {
      title: 'Project Management',
      count: 4,
      icon: <MdManageAccounts className="text-xs md:text-2xl" />,
      image: facility,
      quotes: `
      Mechatric limited has qualified project managers who can deliver world class execution of work scopes. We cover detailed management of project team project staffing technical bid evaluation commercial bid evaluation, vendor selection. We focus on set of resources, ensuring projects are delivered on stipulated time duration, approved cost.
      `
    },
    {
      title: 'Construction of control panels',
      count: 4,
      icon: <SiConstruct3 className="text-xs md:text-2xl" />,
      image: panel,
      quotes: `
      we have highly skilled and qualified electrical engineers who can design, construct and maintain control panels. Single phase, 3 phases panels, Photocell, Automatic transfer pumps, Heavy duty equipment panels, Industrial panels
      `
    },
]
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    for (let value in formData) {
      formData[value] = formData[value]?.trim();
    }
    if (
      !formData?.email ||
      !formData?.contact ||
      !formData?.message ||
      !formData?.lastname ||
      !formData?.firstname
    ) {
      toast.error("Please fill out all fields");
      return;
    }
    if (!validateEmail(formData?.email?.toLowerCase())) {
      toast.error("Kindly input a valid email");
      return;
    }
    const result = phone(formData?.contact, { country: formData?.country });
    if (result?.isValid === false) {
      toast.error("Kindly input a valid Number");
      return;
    }
    setLoading(true);
    try {
      await addDoc(postRef, formData);
      toast.success(
        <>
          <h2 className="text-sm font-semibold">Thanks for reaching us!</h2>
          <p className="text-xs">We will get back to you shortly.</p>
        </>,
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      setFormData((prev) => ({
        ...prev,
        firstname: "",
        lastname: "",
        email: "",
        contact: "",
        message: "",
        country: "",
      }));
      return setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went Wrong, Kindly Retry", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return setLoading(false);
    }
  };
  useEffect(() => {
    setFormData((prev) => ({ ...prev, country: userCountry }));
  }, [userCountry]);
  return (
    <>
      <ToastContainer position="top-center" />
      <ExpandComponent dark open={openModal} close={setOpenModal}>
        <div className="w-full h-full pb-20 sm:pb-0">
          <div className=" relative w-5/6 md:w-1/2 mx-auto space-y-5 bg-white p-5 rounded-lg">
            <button
              onClick={() => setOpenModal(false)}
              className="w-max border-none cursor-pointer text-xs outline-none w-10 h-10 rounded-full flex justify-center items-center absolute right-2 top-2 hover:bg-gray-300 hover:shadow-md"
            >
              <FaTimesCircle />
            </button>
            <div className="flex justify-center items-center gap-5">
              <span className="flex-shrink-0 w-5 h-5 md:w-10 md:h-10 bg-[#ECECEC] rounded-full flex justify-center items-center">
                {selectedService?.icon}
              </span>
                <h2 className="text-center text-sm md:text-xl font-semibold ">{selectedService?.title}</h2>
            </div>
            <img src={selectedService?.image} alt="mechatric img" className="w-full h-28 sm:h-40 md:h-60 rounded-lg object-cover" />
            <p className="text-xs">{selectedService?.quotes}</p>
          </div>
        </div>
      </ExpandComponent>
      {/* nav-bar */}
      <nav className="relative  mx-auto  bg-white shadow shadow-blue w-full">
        <div className="px-6 py-8">
          {/* <!-- Flex Container For All Items --> */}
          <div className="flex items-center justify-between">
            {/* <!-- Flex Container For Logo/Menu --> */}
            <div className="flex items-center space-x-20">
              {/* <!-- Logo --> */}
              <div className="flex justify-start items-center gap-2">
                <img src={logo} alt="mechatric logo" className="w-16" />
                <h1 className="font-sans text-blue font-bold text-sm sm:text-base lg:text-lg xl:text-xl">
                  Mechatric Nigeria Limited
                </h1>
              </div>
              {/* <!-- Left Menu --> */}
            </div>

            {/* <!-- Right Buttons Menu --> */}

            <div className="hidden space-x-8 font-bold md:flex font-sans lg:space-x-10 xl:space-x-20">
              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}
                className="text-blue hover:text-darkBlue cursor-pointer"
              >
                About Us
              </Link>
              <Link
                activeClass="active"
                to="services"
                spy={true}
                smooth={true}
                offset={-60}
                duration={500}
                className="text-blue hover:text-darkBlue cursor-pointer"
              >
                Services
              </Link>
              <Link
                activeClass="active"
                to="gallery"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="text-blue hover:text-darkBlue cursor-pointer"
              >
                Gallery
              </Link>
              <Link
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="text-blue hover:text-darkBlue cursor-pointer"
              >
                Contact
              </Link>
            </div>
            {/* <!-- Hamburger Button --> */}
            {pannel ? (
              <button
                id="menu-btn"
                className="z-30 open block md:hidden focus:outline-none hamburger"
                onClick={() => setPannel(!pannel)}
              >
                <span className="hamburger-top"></span>
                <span className="hamburger-middle"></span>
                <span className="hamburger-bottom"></span>
              </button>
            ) : (
              <button
                id="menu-btn"
                className="z-30 block md:hidden focus:outline-none hamburger"
                onClick={() => setPannel(!pannel)}
              >
                <span className="hamburger-top"></span>
                <span className="hamburger-middle"></span>
                <span className="hamburger-bottom"></span>
              </button>
            )}
          </div>

          {/* <!-- Mobile Menu --> */}
          {pannel ? (
            <div
              id="menu"
              className="absolute  p-5 rounded-lg   bg-blue left-6 right-6 top-20 z-100 shadow shadow-white"
            >
              <div className="flex flex-col items-center justify-center w-full space-y-6 font-bold text-white rounded-sm font-sans divide-y divide-neutral-400 py-4 ">
                <Link
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={500}
                  className="w-full text-center  cursor-pointer"
                  onClick={() => setPannel(!pannel)}
                >
                  About Us
                </Link>
                <Link
                  activeClass="active"
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={500}
                  className="w-full text-center  cursor-pointer"
                  onClick={() => setPannel(!pannel)}
                >
                  Services
                </Link>
                <Link
                  activeClass="active"
                  to="gallery"
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={500}
                  className="w-full text-center  cursor-pointer"
                  onClick={() => setPannel(!pannel)}
                >
                  Gallery
                </Link>
                <Link
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={500}
                  className="w-full text-center  cursor-pointer"
                  onClick={() => setPannel(!pannel)}
                >
                  Contact
                </Link>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </nav>
      {/* hero */}
      <div className="back-image  h-96 sm:h-[26em] md:h-[30rem] lg:h-[32rem] 2xl:h-[34rem] flex flex-col justify-center items-center text-white space-y-6 md:space-y-8 lg:space-y-12 sm:space-y-8 px-2">
        <h1 className="text-2xl font-mono max-w-xs text-center sm:max-w-md sm:text-3xl md:text-4xl md:max-w-lg lg:text-5xl lg:max-w-3xl   tracking-wide">
          Entrust your Facility management needs in the hands of professionals !
        </h1>
        <Link
          activeClass="active"
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <button className="bg-white text-veryDarkViolet px-4 py-2 rounded-md shadow font-semibold sm:px-6 sm:rounded-md md:py-3 md:text-base lg:px-10 lg:text-lg lg:font-bold font-sans">
            Contact us
          </button>
        </Link>
      </div>
      {/* about us */}
      <div id="about" className="section-center space-y-10  ">
        {/* small */}
        {/* text */}
        <div className="space-y-6 md:space-y-4 flex flex-col justify-center items-center xl:hidden ">
          <h3 className="mt-0 text-2xl sm:text-3xl md:text-2xl lg:text-3xl ">
            About Us
          </h3>
          <p className="text-center text-sm sm:text-base max-w-xs sm:max-w-md md:text-base md:text-center md:max-w-lg lg:text-lg lg:max-w-xl">
            Mechatric Nigeria limited was established to fill the huge gap in
            the facility management, engineering and project support services
            sector in Nigeria . we are a dynamic organisation registered purely
            to to fill these huge gap our engineers, technicians and artisans
            have several years of working experience in the Engineering, Oil and
            Gas Industry to meet accurately the clients needs we offer our
            customers a comprehensive repairs solutions, maintenance Solutions,
            installation Solutions, electrical Engineering Services, mechanical
            engineering services, inland and offshore project support services.
          </p>
        </div>
        {/* image */}
        <div className="md:max-w-lg lg:max-w-xl mx-auto xl:hidden">
          <img src={union} alt="about mechatric" />
        </div>
        {/* big */}
        <div className="hidden xl:block relative">
          {/* image */}
          <div className="max-w-xl 2xl:max-w-2xl ">
            <img src={union} alt="about mechatric" />
          </div>
          {/* text */}
          <div className=" absolute left-1/2  right-0  bottom-16 space-y-6 md:space-y-4 flex flex-col justify-start items-start bg-blue text-white max-w-md  py-4 px-6 mx-0 2xl:px-10 2xl:py-8 ">
            <h3 className="mt-0 text-white text-2xl text-left ">About Us</h3>
            <p className=" text-sm text-left">
              Mechatric Nigeria limited was established to fill the huge gap in
              the facility management, engineering and project support services
              sector in Nigeria . we are a dynamic organisation registered
              purely to to fill these huge gap our engineers, technicians and
              artisans have several years of working experience in the
              Engineering, Oil and Gas Industry to meet accurately the clients
              needs we offer our customers a comprehensive repairs solutions,
              maintenance Solutions, installation Solutions, electrical
              Engineering Services, mechanical engineering services, inland and
              offshore project support services.
            </p>
          </div>
        </div>
      </div>
      {/* our services */}
      <div id="services" className="relative">
        {/* conent */}
        <div className="section-center ">
          {/* header */}
          <div className="text-center">
            <p className="text-[#5E6282] sm:text-xl lg:text-2xl font-sans">
              Our Services
            </p>
            <h3 className="mt-2 text-xl sm:text-2xl md:text-2xl md:max-w-sm mx-auto xl:text-3xl xl:max-w-xl">
              Save Time Managing Your Facility With Our Best Services
            </h3>
          </div>
          {/* content 1 */}
          <div className="mt-10 flex flex-col justify-center items-center space-y-8 md:grid md:grid-cols-2 md:space-y-0 gap-x-4 gap-y-10 md:mx-auto md:justify-center md:items-center lg:max-w-xl xl:max-w-full xl:grid-cols-4 xl:mt-18 md:mt-16 ">
            {/* content 1 */}
            {
              services?.map((item, index)=> (
                <div onClick={() => {
                  setSelectedService(item)
                  setOpenModal(true)}} key={index} className="bg-white cursor-pointer shadow-sm shadow-blue rounded-md space-y-2 hover:-translate-y-2 hover:bg-blue hover:text-white duration-500 w-full h-full p-5">
                  <div className="bg-[#ECECEC] px-3 py-3 inline-block rounded-md">
                   {item?.icon}
                  </div>
                  <h1 className="text-2xl font-sans font-bold xl:text-xl"
                  >
                    {item?.title}
                  </h1>
                  <p className="font-sans">{item?.count} project completed</p>
                </div>
              ))
            }
            {/* content 4 */}
            <div className="bg-blue text-white shadow-sm shadow-veryDarkViolet p-5 rounded-md space-y-2 inline-block w-64 hover:-translate-y-4 duration-500 w-full h-full">
              <h1
                className="text-2xl font-sans font-bold xl:text-xl cursor-pointer "
              >
                And <br /> Lots More
              </h1>
              <p className="text-white font-sans">
                over 15 more services rendered
              </p>
            </div>
          </div>
        </div>
        <div className="hidden xl:block absolute -top-10 -z-10 right-0 max-w-3xl">
          <img src={spring} alt="mechatric services" />
        </div>
      </div>
      {/* our Gallery */}
      <div id="gallery" className="section-center space-y-10">
        {/* header */}
        <div className="text-center">
          <p className="text-[#5E6282] sm:text-xl lg:text-2xl font-sans">
            Our Gallery
          </p>
          <h3 className="mt-2 text-xl sm:text-2xl md:text-2xl md:max-w-sm mx-auto xl:text-3xl xl:max-w-xl">
            Hard Work and Due Diligence
          </h3>
        </div>
        {/* all cont */}
        <div className="space-y-6 md:grid md:grid-cols-2 md:space-y-0 md:gap-6 md:w-10/12 md:mx-auto xl:grid-cols-3 xl:w-full">
          {/* cont1 */}
          <div className="bg-white flex flex-col shadow-sm rounded-md max-w-xs shadow-veryDarkViolet mx-auto space-y-4 px-4 py-6 xl:row-span-2 lg:w-full">
            <img
              src={gal1}
              alt="mechatric gallery"
              className="xl:hidden h-60 w-full object-cover"
            />
            <img
              src={gal7}
              alt="mechatric gallery"
              className="hidden h-full object-cover xl:block"
            />
            {/* text */}
            <div className="space-y-2 xl:hidden flex-shrink-0">
              <h3 className="mt-0 text-left text-lg">Facility Management</h3>
              <p className="text-[#999999] font-sans text-xs">Oil Rig Delta</p>
            </div>
            <div className="space-y-2 hidden flex-shrink-0 xl:block">
              <h3 className="mt-0 text-left text-lg">Plumbing Repairs</h3>
              <p className="text-[#999999] font-sans text-xs">
                Horlad Hotel & Suites
              </p>
            </div>
          </div>
          {/* cont2 */}
          <div className="bg-white shadow-sm rounded-md max-w-xs shadow-veryDarkViolet mx-auto space-y-4 px-4 py-6 lg:w-full">
            <img src={gal2} alt="mechatric gallery" className="h-60 w-full object-cover" />
            {/* text */}
            <div className="space-y-2">
              <h3 className="mt-0 text-left text-lg">Instrument Engineering</h3>
              <p className="text-[#999999] font-sans text-xs">
                Dangote Refinery
              </p>
            </div>
          </div>
          {/* cont3 */}
          <div className="bg-white shadow-sm rounded-md max-w-xs shadow-veryDarkViolet mx-auto space-y-4 px-4 py-6 lg:w-full">
            <img src={gal3} alt="mechatric gallery" className="h-60 w-full object-cover" />
            {/* text */}
            <div className="space-y-2">
              <h3 className="mt-0 text-left text-lg">Preventive Maintenace</h3>
              <p className="text-[#999999] font-sans text-xs">Oando Delta</p>
            </div>
          </div>
          {/* cont4 */}
          <div className="bg-white shadow-sm rounded-md max-w-xs shadow-veryDarkViolet mx-auto space-y-4 px-4 py-6 md:py-2 xl:py-6 lg:w-full">
            <img
              src={gal4}
              alt="mechatric gallery"
              className="md:hidden h-60 w-full object-cover xl:block"
            />
            <img
              src={gal5}
              alt="mechatric gallery"
              className="hidden h-60 w-full object-cover md:block xl:hidden"
            />
            {/* text */}
            <div className="space-y-2 md:hidden xl:block">
              <h3 className="mt-0 text-left text-lg">
                Electrical Installation
              </h3>
              <p className="text-[#999999] font-sans text-xs">
                Lagos Oil depot
              </p>
            </div>
            <div className="space-y-2 hidden md:block xl:hidden">
              <h3 className="mt-0 text-left text-lg">Plumbing Repairs</h3>
              <p className="text-[#999999] font-sans text-xs">
                Horlad Hotel & Suites
              </p>
            </div>
          </div>
          {/* cont5 */}
          <div className="bg-white shadow-sm rounded-md max-w-xs mx-auto md:mx-0 md:max-w-full shadow-veryDarkViolet space-y-4 px-4 py-6 md:col-start-1 md:col-span-2  xl:col-span-1 xl:py-2 ">
            <img src={gal5} alt="mechatric gallery" className="md:hidden " />
            <img
              src={gal4}
              alt="mechatric gallery"
              className="hidden md:block h-60 w-full object-cover xl:hidden"
            />
            <img
              src={gal1}
              alt="mechatric gallery"
              className="hidden h-60 w-full object-cover md:hidden xl:block "
            />
            {/* text */}
            <div className="space-y-2 md:hidden">
              <h3 className="mt-0 text-left text-lg">Plumbing Repairs</h3>
              <p className="text-[#999999] font-sans text-xs">
                Horlad Hotel & Suites
              </p>
            </div>
            <div className="space-y-2 hidden md:block xl:hidden">
              <h3 className="mt-0 text-left text-lg">
                Electrical Installation
              </h3>
              <p className="text-[#999999] font-sans text-xs">
                Lagos Oil depot
              </p>
            </div>
            <div className="space-y-2 hidden xl:block">
              <h3 className="mt-0 text-left text-lg">Facility Management</h3>
              <p className="text-[#999999] font-sans text-xs">Oil Rig Delta</p>
            </div>
          </div>
        </div>
      </div>
      {/* contact us */}
      <div id="contact" className="bg-blue text-white py-3 xl:py-0 ">
        <div className="section-center xl:flex lg:justify-center lg:items-end">
          {/* word */}
          <div className="xl:w-2/3">
            <h3 className="text-white mt-0 text-2xl xl:hidden lg:text-3xl">
              Get in Touch Today !
            </h3>
            {/* form */}
            <div className="section-center my-16 xl:my-12">
              <h3 className="text-white mt-0 text-3xl hidden xl:block ">
                Get in Touch Today !
              </h3>
              <div className="mt-8 md:mt-16 xl:mt-10 xl:w-3/4 xl:mx-auto">
                <form
                  onSubmit={handleSubmit}
                  className=" space-y-4 mx-auto lg:space-y-8 "
                >
                  {/* first container */}
                  <div className="lg:flex justify-between items-center lg:space-x-10 lg:space-y-0 space-y-4">
                    {/* first name */}
                    <div className="flex flex-col space-y-2 lg:w-1/2 ">
                      <label htmlFor="firstname" className="font-alata text-lg">
                        First Name
                      </label>
                      <input
                        type="text"
                        name="firstname"
                        value={formData?.firstname}
                        onChange={handleChange}
                        placeholder="Please Input your First Name"
                        className="font-alata border px-2 py-2 text-sm max-w-2xl outline-blue text-black"
                      />
                    </div>
                    {/* last name */}
                    <div className="flex flex-col space-y-2 lg:w-1/2 ">
                      <label htmlFor="firstname" className="font-alata text-lg">
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="lastname"
                        value={formData?.lastname}
                        onChange={handleChange}
                        placeholder="Please Input your Last Name"
                        className="font-alata border px-2 py-2 text-sm max-w-2xl outline-blue text-black"
                      />
                    </div>
                  </div>
                  {/* second-container */}
                  <div className="lg:flex justify-between items-center lg:space-x-10 lg:space-y-0 space-y-4">
                    {/* Email */}
                    <div className="flex flex-col space-y-2 lg:w-1/2 ">
                      <label htmlFor="email" className="font-alata text-lg">
                        Email Address
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData?.email}
                        onChange={handleChange}
                        placeholder="Please Input your Email Address here"
                        className="font-alata border px-2 py-2 text-sm max-w-2xl outline-blue text-black"
                      />
                    </div>
                    {/* Contact number */}
                    <div className="flex flex-col space-y-2 lg:w-1/2  ">
                      <label
                        htmlFor="Contact Number"
                        className="font-alata text-lg"
                      >
                        Contact Number
                      </label>
                      <input
                        type="Number"
                        name="contact"
                        value={formData?.contact}
                        onChange={handleChange}
                        placeholder="Please Input your Contact Number here"
                        className="font-alata border px-2 py-2 text-sm max-w-2xl outline-blue text-black"
                      />
                    </div>
                  </div>

                  {/* message */}
                  <div className="flex flex-col space-y-2 ">
                    <label htmlFor="message" className="font-alata text-lg">
                      Message
                    </label>
                    <textarea
                      type="text"
                      name="message"
                      value={formData?.message}
                      onChange={handleChange}
                      placeholder="Input message here"
                      className="font-alata border resize-none text-black px-2 py-2 text-sm max-w-4xl outline-blue h-32"
                    />
                  </div>
                  {/* submit button */}
                  <button
                    type="submit"
                    className="mx-auto hover:bg-gray-100 text-center px-6 rounded-md shadow-md text-blue py-2 bg-white font-sans flex justify-center"
                  >
                    {loading ? (
                      <FaSpinner className="animate-spin" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
          {/* img */}
          <div className="hidden xl:block xl:w-1/3">
            <img src={cont} alt="mechatric contact" />
          </div>
        </div>
      </div>
      {/* footer */}
      <div>
        <div className="section-center md:max-w-3xl lg:max-w-5xl xl:max-w-6xl">
          <div className="flex flex-col justify-center items-center space-y-6 md:flex-row  md:items-start md:space-y-0 md:gap-6 md:justify-between ">
            {/* logo */}
            <div className="max-w-xs ">
              <img src={flogo} alt="mechatric logo" className="w-32 sm:w-44" />
            </div>
            {/* office address */}
            <div className="w-52 md:w-48  text-center md:text-left space-y-1 md:space-y-2 lg:space-y-4">
              <h3 className="mt-0 text-lg sm:text-xl md:text-left lg:text-2xl ">
                Office Address
              </h3>
              <div className="space-y-5">
                <div className="space-y-1">
                  <h2 className="text-base font-semibold">Head Office</h2>
                  <p className="font-sans lg:text-base text-xs sm:text-sm ">
                    Shop 79 falomo, Powa Complex Police Barack, Ikoyi Lagos state,
                    Nigeria
                  </p>
                </div>
                <div className="space-y-1">
                  <h2 className="text-base font-semibold">Branch Office</h2>
                  <p className="font-sans lg:text-base text-xs sm:text-sm ">
                    No 23 Nehemiah School Street Off Km 2 Ughelli Patani Express Road, Okuokoko Okpe LGA, Delta State, Nigeria
                  </p>
                </div>
              </div>
            </div>
            {/* office Hours */}
            <div className="w-52 text-center space-y-1 md:text-left lg:space-y-4  md:space-y-2 ">
              <h3 className="mt-0 text-lg sm:text-xl md:text-left lg:text-2xl">
                Office Hours
              </h3>
              <p className="font-sans text-xs sm:text-sm lg:text-base">
                Monday - Saturday: <br /> 8:00-17:00 <br /><br /> Sunday: <br /> Closed
              </p>
            </div>
            {/* social media */}
            <div className="w-52 text-center space-y-2 md:text-left  md:space-y-2   lg:space-y-5">
              <h3 className="mt-0 text-lg sm:text-xl md:text-left lg:text-2xl">
                Follow Us
              </h3>
              <p className="font-sans text-xs ">
                <div className="flex justify-center gap-4 items-center md:justify-start md:gap-4 md:flex-col md:items-start">
                  <a
                    href="https://web.facebook.com/mechatric2022/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="sm:text-xl lg:text-xl md:flex md:justify-center md:items-center gap-2 xl:gap-3"
                  >
                    <FaFacebook className="text-blue" s />
                    <h2 className="font-sans text-base hidden md:block">
                      Facebook
                    </h2>
                  </a>
                  <a
                    href="https://www.instagram.com/mechatriclimited/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="lg:text-xl sm:text-xl md:flex md:justify-center md:items-center gap-2 xl:gap-3"
                  >
                    <GrInstagram className="text-blue" />
                    <h2 className="font-sans text-base hidden md:block">
                      Instagram
                    </h2>
                  </a>

                  <a
                    href="https://twitter.com/MechatricL"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="lg:text-xl sm:text-xl md:flex md:justify-center md:items-center gap-2 xl:gap-3"
                  >
                    <FaTwitter className="text-blue" />
                    <h2 className="font-sans text-base hidden md:block">
                      Twitter
                    </h2>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/mechatric-nig-ltd-mechatric-5720a7239/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="lg:text-xl sm:text-xl md:flex md:justify-center md:items-center gap-2 xl:gap-3"
                  >
                    <FaLinkedinIn className="text-blue" />
                    <h2 className="font-sans text-base hidden md:block">
                      LinkedIn
                    </h2>
                  </a>
                  <a
                    href="tel:+2349163928066"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="lg:text-xl sm:text-xl md:flex md:justify-center md:items-center gap-2 xl:gap-3"
                  >
                    <FaPhoneAlt className="text-blue" />
                    <h2 className="font-sans text-sm hidden md:block">
                      +2349163928066
                    </h2>
                  </a>
                </div>
              </p>
            </div>
          </div>
        </div>
        {/* copyr */}
        <div className="bg-blue text-white mx-auto py-2">
          <p className="text-center font-sans text-xs lg:py-3">
            Copyright @Mechatric 2022 All Right Reserved
          </p>
        </div>
      </div>
      {/* whatsapp feature */}
      <div
        className="bg-green-500 inline-block rounded-full text-white p-3 lg:p-4 fixed
          bottom-10
          right-10 animate-bounce"
      >
        <a
          href="https://wa.me/2349163928066"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsWhatsapp className="text-3xl xl:text-4xl" />
        </a>
      </div>
    </>
  );
}

export default App;
